
@media screen and (min-width: 769px) {

  .occupation {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .occupation label {
    flex: 0 1 50%;
    min-height: 56px !important;
    margin-top: -1px;
  }

  .occupation label:last-child {
    border-top-right-radius: 0 !important;
  }

  .smFieldGroup {
    flex: 0 1 37%;
  }
}