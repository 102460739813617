
.field {
  text-align: left;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.field label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1.2;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.field input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border: solid 1px #747474;
  border-radius: 2px;
}

.field label:hover input ~ .icon {
  background-color: #f9f9f9;
}

.field label input:checked ~ .icon {
  background-color: #38d19e;
  border-color: #38d19e;
}

.icon:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 1px;
  width: 4px;
  height: 11px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  display: none;
}

.field input:checked ~ .icon:after {
  display: block;
}

.circle {
  composes: field;
}

.circle .icon {
  border-radius: 13px;
}
