
.field {
  position: relative;
  text-align: left;
  padding: 0 12px;
  display: flex;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.field:focus-within {
  border-color: #38d19e;
}

.field select {
  font-size: 16px;
  line-height: 1.2;
  color: #3d3d3d;
  background-color: transparent;
  border: 0 none;
  padding-right: 20px;
  width: 0;
  flex: 1 1 auto;
  outline: none;
  position: relative;
  box-shadow: none;
  z-index: 1;
}

.field label {
  font-size: 12px;
  color: #8a8a8b;
  position: absolute;
  top: -2px;
  left: 16px;
  transition: all .3s;
  z-index: 0;
}

.error {
  font-size: 12px;
  line-height: 1.2;
  color: #b42527;
  position: absolute;
  top: 58px;
  left: 16px;
  z-index: 1;
  display: none;
}

.prefix {
  margin-right: 15px;
  flex: 0 0 auto;
  border-width: 0;
  height: 54px;
}

.prefix,
.prefix select {
  width: 100px;
  max-width: 100%;
  padding: 0;
  margin-left: -2px;
}

.prefixWithLabel {
  composes: prefix;
  border-right: solid 1px #e4e4e4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.prefixWithLabel:focus-within {
  border-right-color: #e4e4e4;
}

.prefixWithLabel .label {
  left: 0;
}

.valid,
.invalid {
  composes: field;
}

.valid {
  border-color: #38d19e;
}

.invalid {
  border-color: #ff6366;
}

.invalid .error {
  display: block;
}
