
.tooltip {
  line-height: 1;
  position: relative;
}

.static {
  position: static;
}

.icon {
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: #a4a4a4;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.icon:hover {
  background-color: #747474;
}

.icon:before {
  content: "";
  visibility: hidden;
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid #a4a4a4;
  position: absolute;
  right: 2px;
  top: -8px;
  opacity: 0;
  transition: opacity .3s ease-out;
  z-index: -1;
}

.box {
  display: block;
  visibility: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.33;
  padding: 16px;
  text-align: left;
  border: 1px solid #a4a4a4;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
  background-color: #fff;
  width: 320px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50.1%);
  opacity: 0;
  transition: opacity .3s ease-out;
  z-index: -1;
}

.icon:hover:before,
.icon:hover + span {
  visibility: visible;
  opacity: 1;
  z-index: 20;
}
