
.diffAddress {
  color: #3d3d3d;
  font-size: 14px;
  padding: 0;
}

.diffAddress label > span {
  top: -2px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 40px;
}

.resCountryError {
  padding: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #fff;
}

.resCountryError h3 {
  display: flex;
}

.resCountryError h3 i {
  color: #ff6366;
  font-size: 20px;
  margin: -4px 4px -4px -2px;
}

.resCountryError .link {
  font-size: 14px;
  text-align: right;
}

@media screen and (min-width: 769px) {

  .resCountry {
    flex: 0 1 37%;
  }

}