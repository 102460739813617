
.platform {
  position: relative;
}

.platformName {
  color: #3d3d3d;
  padding: 0 50px 0 16px;
  display: flex;
  align-items: center;
  height: 56px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
}

.edit, .edit:hover, .edit:focus {
  text-decoration: none;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.edit i {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.cats > div {
  border-top: 2px solid #e4e4e4;
  padding-top: 24px;
}

.cats > div:not(:first-child) {
  margin-top: 20px;
}

.cats h4 {
  color: #3d3d3d;
  font-size: 16px;
  position: relative;
}

.cats h4 .edit {
  top: -6px;
  right: -6px;
}

.items > div > span {
  color: #a4a4a4;
  font-size: 13px;
  line-height: 1.3;
  display: block;
  margin-top: 12px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 40px;
}

@media screen and (min-width: 769px) {

  .platformName {
    padding: 0 58px 0 24px;
    height: 64px;
  }

  .edit, .edit:hover, .edit:focus {
    top: 16px;
    right: 16px;
  }

  .groups {
    display: flex;
    flex-wrap: wrap;
  }

  .groups > div {
    flex: 1 0 50%;
  }

  .groups > div:first-child:not(:last-child) {
    padding-right: 32px;
  }

  .cats h4 .edit {
    top: -8px;
    right: -8px;
  }

}

@media screen and (min-width: 1201px) {

  .genericModal > div {
    width: 1174px;
    min-height: 755px;
  }

}
