
.list ul {
  text-align: left;
  list-style: none;
  margin-left: 20px;
  position: relative;
}

.list li {
  position: relative;
  padding-left: 16px;
  line-height: 1.43;
  text-indent: 0;
  margin: 8px 0;
}

.list li::before {
  content: "";
  background-color: #38d19e;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 7px;
  left: -3px;
}

.dotted {
  composes: list;
}

.dotted ul::before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  width: 3px;
  background-image: linear-gradient(to bottom, rgba(51, 51, 51, 0.3) 1px, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 2px 6px;
  background-repeat: repeat-y;
}

.checklist {
  composes: list;
}

.checklist li {
  padding-left: 20px;
  margin: 16px 0;
}

.checklist li::before {
  content: "\f100";
  color: #fff;
  font-family: "cticon";
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  background-color: #38d19e;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  top: 0;
  left: -9px;
}
