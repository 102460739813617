
.container {
  composes: container from '../assets/css/index.css';
}

.components {
  padding: 0 24px;
  margin: 70px 0;
}

.components a, .components a:visited {
  color: #009865;
  text-decoration: none;
}

.components a:hover, .components a:active, .components a:focus {
  color: #0b593f;
  text-decoration: underline;
}

.components h1 {
  text-align: center;
}

.components h2 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.components h3 {
  color: #2b6eec;
  font-size: 18px;
  padding-bottom: 2px;
  margin: 40px 0 12px;
  border-bottom: solid 1px #2b6eec;
}

.components h4 {
  font-size: 14px;
  margin-bottom: 16px;
}

.components textarea {
  white-space: pre;
  text-align: left;
  font-size: 13px;
  resize: vertical;
  width: 100%;
  height: 100%;
  border: 1px solid #cfdae3;
  border-radius: 3px;
  color: #1e1e1e;
  padding: 2px 2px 8px 6px;
}

.components textarea:focus {
  outline: none;
}

.note {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 24px;
}

.demoPanel {
  margin: 0;
}

.demoSlider {
  max-width: 282px;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {

  .components {
    padding: 0;
  }

  .container {
    width: 700px;
  }

  .btnHolder {
    margin: 0;
    width: 250px;
  }

  .note {
    margin-bottom: 24px;
  }

  .demoSlider {
    max-width: 300px;
    margin: 0 0 0 21px;
  }

}

@media screen and (min-width: 993px) {

  .container {
    width: 900px;
  }

}