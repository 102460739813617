
fieldset {
  border: 0 none;
}

fieldset h3 {
  margin: 32px 0 12px;
}

.fieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

.fieldRow.responsive {
  position: relative;
  padding-top: 22px;
}

.fieldRow.responsive label.master {
  position: absolute;
  top: 17px;
  left: 20px;
  font-size: 12px;
  line-height: 1.33;
  color: #8a8a8b;
  display: none;
}

.fieldRow.responsive .fieldGroup:not(.mobile) {
  display: none;
}

.fieldsGroup {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.fieldGroup {
  margin: 10px 6px;
  flex: 1 1 0%;
}

.void {
  margin: 0;
}

.fieldGroupBasisXS {
  composes: fieldGroup;
  flex: 0 0 90px;
}

.radioHolder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -8px;
}

@media screen and (min-width: 769px) {

  fieldset h3 {
    margin-bottom: 0;
  }

  .fieldRow {
    flex-direction: row;
  }

  .fieldRow.responsive .fieldGroup.mobile {
    display: none;
  }
  
  .fieldRow.responsive label.master,
  .fieldRow.responsive .fieldGroup:not(.mobile) {
    display: block;
  }
  
  .fieldsGroup:first-child {
    margin-left: 12px;
  }

  .fieldsGroup:last-child {
    margin-right: 12px;
  }

  .void {
    margin: 10px 6px;
  }
  
  .fieldsGroupBasisM {
    composes: fieldsGroup;
    flex: 0 0 297px;
  }

  .fieldGroupBasisS {
    composes: fieldGroup;
    flex: 0 0 170px;
  }

  .radioHolder {
    padding-bottom: 8px;
    margin-bottom: 12px;
  }
  
}