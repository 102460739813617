.field {
  position: relative;
  text-align: left;
  padding-left: 16px;
  display: flex;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.field:focus-within {
  border-color: #38d19e;
}

.valid, .invalid, .prefixed, .prefix {
  composes: field;
}

.prefixedWithLabel {
  composes: prefixed;
}

.prefixWithLabel {
  composes: prefix;
}

.field > input {
  font-size: inherit;
  line-height: 1.5;
  color: #3d3d3d;
  background-color: transparent;
  border: 0 none;
  width: 0;
  padding-right: 12px;
  flex: 1 1 auto;
  outline: none;
  position: relative;
  z-index: 1;
}

.field > input::-webkit-calendar-picker-indicator {
  opacity: 100;
  block-size: 20px !important;
}

.field > label {
  font-size: 12px;
  color: #747474;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all .2s;
  z-index: 0;
}

.field > input:placeholder-shown + .label {
  font-size: inherit;
  top: 16px;
  left: 16px;
}

.prefixed > input:placeholder-shown + .label {
  left: 129px;
}

.field > input:focus + .label,
.field > input:not(:placeholder-shown) + .label,
.prefixed > input:focus + .label,
.prefixed > input:not(:placeholder-shown) + .label {
  font-size: 12px;
  line-height: 1.5;
  top: 0;
  left: 16px;
}

.prefixedWithLabel > input:focus + .label,
.prefixedWithLabel > input:not(:placeholder-shown) + .label {
  left: 129px;
}

.prefixWithLabel > input:focus + .label,
.prefixWithLabel > input:not(:placeholder-shown) + .label {
  left: 0;
}

.placeholder {
  position: absolute;
  top: 15px;
  left: 16px;
  color: #b9b9b9;
  opacity: 0;
  z-index: 0;
  transition: opacity .35s;
}

.prefixed > .placeholder {
  left: 129px;
}

.prefix {
  margin-right: 13px;
  flex: 0 0 auto;
  border-width: 0;
  border-right: solid 1px #e4e4e4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 54px;
}

.prefix:focus-within {
  border-right-color: #e4e4e4;
}

.prefix,
.prefix > input {
  width: 100px;
  max-width: 100%;
  padding: 0;
}

.prefix > input:placeholder-shown + .label {
  left: 0;
}

.prefix > .placeholder {
  left: 0;
}

.field > input:focus:placeholder-shown ~ .placeholder {
  opacity: 1;
}

.field.datalist > input:placeholder-shown + .label,
.field.currency > input:placeholder-shown + .label {
  right: 56px;
}

.field.datalist.currency > input:placeholder-shown + .label {
  right: 70px;
}

.field.datalist > input:focus + .label,
.field.datalist > input:not(:placeholder-shown) + .label,
.prefixed.datalist > input:focus + .label,
.prefixed.datalist > input:not(:placeholder-shown) + .label,
.field.currency > input:focus + .label,
.field.currency > input:not(:placeholder-shown) + .label,
.prefixed.currency > input:focus + .label,
.prefixed.currency > input:not(:placeholder-shown) + .label,
.field.datalist.currency > input:focus + .label,
.field.datalist.currency > input:not(:placeholder-shown) + .label,
.prefixed.datalist.currency > input:focus + .label,
.prefixed.datalist.currency > input:not(:placeholder-shown) + .label {
  right: 16px;
}

.currency input {
  padding-right: 50px;
}

.currencyCode {
  font-size: inherit;
  color: #b1b0b2;
  text-transform: uppercase;
  position: absolute;
  top: 16px;
  right: 16px;
}

.error {
  font-size: 12px;
  line-height: 1;
  color: #661315;
  position: absolute;
  top: 58px;
  left: 16px;
  z-index: 1;
  display: none;
}

.valid {
  border-color: #38d19e;
}

.invalid {
  border-color: #ff6366;
}

.invalid .error {
  display: block;
}
