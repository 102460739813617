
.main {
  composes: main from '../assets/css/index.css';
  padding-bottom: 130px;
}

.desc {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 2px;
}

.compliance {
  composes: desc;
  text-align: center;
  margin-bottom: 32px;
}

.statusCert {
  composes: desc;
  margin-bottom: 32px;
}

.fatca {
  font-size: 14px;
  line-height: 1.5;
  margin: 8px 0 20px;
}

.fatca ul {
  margin-left: 14px;
}

.fatca li {
  margin: 4px 0;
}

.irsSoleOwner {
  font-size: 14px;
  padding-bottom: 0;
}

.changeNonUS,
.sorry {
  padding: 24px;
  margin-top: 12px;
  border-radius: 8px;
  background-color: #fff;
}

.changeNonUS h3,
.sorry h3 {
  display: flex;
}

.changeNonUS h3 i,
.sorry h3 i {
  font-size: 18px;
  margin: -4px 4px -4px -2px;
}

.sorry {
  margin-bottom: 12px;
}

.sorry h3 i {
  color: #ff6366;
  font-size: 20px;
}

.sorry .link {
  font-size: 14px;
  text-align: right;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 48px;
  margin-bottom: 0;
}
