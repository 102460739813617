
footer {
  color: #81909d;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  border-top: 1px solid #d8d8d8;
}

.container {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.menu a {
  color: #81909d;
  text-decoration: underline;
  display: inline-block;
}

.menu a:hover {
  color: #075da7;
}

.delimeter {
  margin: 0 16px 0 15px;
  border-right: 1px solid #d8d8d8;
}

.copyright {
  color: #8a8a8b;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  margin-top: 22px;
}


/* Media queries */

@media screen and (min-width: 769px) {
  
  .container {
    flex-direction: row;
  }
  
  .copyright {
    order: 1;
    text-align: left;
    margin-top: 0;
  }
  
  .menu {
    order: 2;
  }
  
}

@media screen and (min-width: 1201px) {
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
}
