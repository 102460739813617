
.skip {
  text-align: center;
  position: relative;
  margin-bottom: 24px;
}

.skip:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 1px solid #ccc;
  z-index: 0;
}

.skip a, .skip a:visited {
  color: #888;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  line-height: 25px;
  background-color: #f8f8f8;
  padding: 0 10px;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.skip a:hover {
  text-decoration: none;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 40px;
}