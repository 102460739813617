
.container {
  composes: container from '../assets/css/index.css';
  padding: 12px 12px 48px;
  width: auto;
}

.content {
  composes: content from '../assets/css/index.css';
  text-align: center;
  padding: 0;
  width: auto;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.08);
}

.content > div {
  padding: 32px 16px 44px;
  background-image: url(../assets/img/traderscard-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
}

.content h1 {
  margin: 0 0 16px;
}

.content h2 {
  margin-bottom: 24px;
}

.card img {
  width: 304px;
  margin: 0 auto;
}

.hlights {
  margin: 24px 0 40px;
}

.hlights > div {
  line-height: 1.29;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  margin: 12px auto 24px;
  width: 180px;
}

.hlights i {
  font-size: 36px;
  margin-bottom: 8px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  padding: 0 16px;
  margin: 0;
}

.skip {
  margin-top: 20px;
}

@media screen and (min-width: 561px) {

  .container {
    padding: 24px 24px 56px;
  }

  .hlights {
    display: flex;
    padding: 0 32px;
    margin: 0 0 10px;
  }

  .btnHolder > a {
    max-width: unset;
    min-width: 65%;
    width: auto;
  }

}

@media screen and (min-width: 769px) {

  .container {
    padding: 0 0 64px;
    width: 664px;
  }

  .content {
    margin: 40px 0;
  }

  .content > div {
    padding: 40px 24px;
  }

}