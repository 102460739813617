
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: "cticon";
  src: url("../fonts/cticon.eot");
  src: url("../fonts/cticon.eot?#iefix") format("embedded-opentype");
  src: url("../fonts/cticon.woff2") format("woff2"),
       url("../fonts/cticon.woff") format("woff"),
       url("../fonts/cticon.ttf") format("truetype"),
       url("../fonts/cticon.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "cticon";
    src: url("../fonts/cticon.svg#cticon") format("svg");
  }
}

[class^="cticon-"]:before, [class*=" cticon-"]:before {
  display: inline-block;
  font-family: "cticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.cticon-done:before {
  content: "\f100";
}
.cticon-downloading-updates:before {
  content: "\f101";
}
.cticon-client-management:before {
  content: "\f102";
}
.cticon-pencil:before {
  content: "\f103";
}
.cticon-ok:before {
  content: "\f104";
}
.cticon-plus--:before {
  content: "\f105";
}
.cticon-back:before {
  content: "\f106";
}
.cticon-collapse-arrow:before {
  content: "\f107";
}
.cticon-expand-arrow:before {
  content: "\f108";
}
.cticon-forward:before {
  content: "\f109";
}
.cticon-direction:before {
  content: "\f10a";
}
.cticon-close:before {
  content: "\f10b";
}
.cticon-sad:before {
  content: "\f10c";
}
.cticon-search:before {
  content: "\f10d";
}
.cticon-ok-2:before {
  content: "\f10e";
}
.cticon-plus---2:before {
  content: "\f10f";
}
.cticon-cancel:before {
  content: "\f110";
}
.cticon-info:before {
  content: "\f111";
}
.cticon-trash-can:before {
  content: "\f112";
}
.cticon-forward-arrow:before {
  content: "\f113";
}
.cticon-calendar:before {
  content: "\f114";
}
.cticon-dollar-euro-exchange:before {
  content: "\f115";
}
.cticon-security-shield-green:before {
  content: "\f116";
}
.cticon-protect:before {
  content: "\f117";
}
.cticon-protect-2:before {
  content: "\f118";
}
.cticon-denied:before {
  content: "\f119";
}
.cticon-add-user-male:before {
  content: "\f11a";
}
.cticon-regular-document:before {
  content: "\f11b";
}
.cticon-loader:before {
  content: "\f11c";
}
.cticon-checked-identification-documents:before {
  content: "\f11d";
}
.cticon-eye:before {
  content: "\f11e";
}
.cticon-invisible:before {
  content: "\f11f";
}
.cticon-eye-2:before {
  content: "\f120";
}
.cticon-invisible-2:before {
  content: "\f121";
}
.cticon-geography:before {
  content: "\f122";
}
.cticon-mobile-taxi-service:before {
  content: "\f123";
}
.cticon-relaxed-working-with-a-laptop:before {
  content: "\f124";
}
