.field {
  position: relative;
  text-align: left;
  padding: 0 14px 0 16px;
  display: flex;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.field:focus-within, 
.ddShown {
  border-color: #38d19e;
}

.valid, .invalid, .prefixed, .prefix {
  composes: field;
}

.prefixedWithLabel {
  composes: prefixed;
}

.prefixWithLabel {
  composes: prefix;
}

.field > input[type=text] {
  font-size: inherit;
  line-height: 1.5;
  color: #3d3d3d;
  background-color: transparent;
  border: 0 none;
  width: 0;
  padding-right: 12px;
  flex: 1 1 auto;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.field > label {
  color: #747474;
  font-size: 12px;
  line-height: 1.5;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all .2s;
  z-index: 0;
}

.field > input[type=text]:placeholder-shown + .label {
  font-size: inherit;
  top: 15px;
  left: 16px;
}

.prefixed > input[type=text]:placeholder-shown + .label {
  left: 129px;
}

.field > input:focus + .label,
.field > input[type=text]:not(:placeholder-shown) + .label,
.ddShown > input[type=text]:placeholder-shown + .label,
.prefixed > input:focus + .label,
.prefixed > input[type=text]:not(:placeholder-shown) + .label {
  font-size: 12px;
  line-height: 1.5;
  top: 0;
  left: 16px;
}

.prefixedWithLabel > input[type=text]:not(:placeholder-shown) + .label {
  left: 129px;
}

.field > .icon {
  position: absolute;
  top: 15px;
  left: 16px;
  z-index: 1;
}

.field > .icon + input[type=text] {
  padding-left: 32px;
}

.ddIcon {
  color: #747474;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: transform .15s linear;
}

.ddShown .ddIcon {
  transform: rotate(180deg);
}

.currency {
  padding-right: 58px;
}

.currencyCode {
  font-size: inherit;
  color: #b1b0b2;
  text-transform: uppercase;
  position: absolute;
  top: 16px;
  right: 16px;
}

.currency:not(.ddShown) > input[type=text]:placeholder-shown + .label {
  right: 70px;
}

.error {
  font-size: 12px;
  line-height: 1;
  color: #661315;
  position: absolute;
  top: 58px;
  left: 16px;
  z-index: 1;
  display: none;
}

.valid {
  border-color: #38d19e;
}

.invalid {
  border-color: #ff6366;
}

.invalid .error {
  display: block;
}

.dropdown {
  padding: 12px 0;
  background-color: #fff;
  border: solid 1px #38d19e;
  border-radius: 4px;
  width: 100%;
  min-width: 200px;
  position: absolute;
  top: 53px;
  left: 0;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
  transition: all .25s ease-out;
}

.ddShown .dropdown {
  opacity: 1;
  max-height: 300px;
  overflow: auto;
  z-index: 5;
}

.dropdownSearch {
  padding: 6px 16px 12px;
  display: flex;
  align-items: center;
  position: relative;
}

.dropdownSearch input {
  font-size: 16px;
  line-height: 1.5;
  background-color: transparent;
  flex: 1 1 auto;
  border: 0 none;
  outline: none;
  position: relative;
  z-index: 1;
}

.dropdownSearch i {
  color: #747474;
  font-size: 18px;
  position: absolute;
  top: 5px;
  right: 16px;
  z-index: 0;
}

.dropdown .items > div {
  line-height: 1.3;
  white-space: nowrap;
  padding: 6px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown .items > div:hover {
  color: #38d19e;
}

.dropdown .icon {
  margin-right: 8px;
}

.prefix {
  margin-right: 13px;
  flex: 0 0 auto;
  border-width: 0;
  height: 54px;
  position: static;
}

.prefixWithLabel {
  border-right: solid 1px #e4e4e4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.prefix:focus-within {
  border-right-color: #e4e4e4;
}

.prefix,
.prefix > input[type=text] {
  width: 100px;
  max-width: 100%;
  padding: 0;
}

.prefix > input[type=text] {
  padding-right: 30px;
}

.prefix > .ddIcon {
  position: relative;
  right: 30px;
}
