
.ownership label {
  padding-top: 16px;
  padding-bottom: 16px;
}

.multiOwners label:last-child {
  border-color: #23cd94;
  z-index: 1;
}

.owners {
  padding: 1px 16px 12px 16px;
  border: 1px solid #23cd94;
  border-top-width: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
  margin: -16px 0 12px;
  position: relative;
  z-index: 2;
}

h3.ownerTitle {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ownerTitle span {
  color: #23cd94;
  padding-left: 26px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.ownerTitle span:hover {
  color: #009865;
}

.ownerTitle i {
  font-size: 18px;
  position: absolute;
  top: -4px;
  left: 0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.addOwner {
  margin: 16px 0;
}

.addOwner a {
  color: #23cd94;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.addOwner a:hover {
  color: #009865;
}

.addOwner a i {
  font-size: 18px;
  margin-left: 6px;
}

.residenceCity > label {
  left: 96px !important;
}

.nap {
  width: 66px;
  padding: 0 10px 0 0;
}

.nap > input {
  width: 100%;
}

.desc {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

@media screen and (min-width: 769px) {

  .owners {
    padding: 1px 24px 12px 44px;
  }

}