
.main {
  composes: main from '../assets/css/index.css';
  text-align: center;
}

.main h1 {
  margin-top: 80px;
}

.content {
  padding: 0 24px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 54px;
}


/* Media queries */

@media screen and (min-width: 561px) {

  .content {
    width: 440px;
    margin: 0 auto;
    padding: 0;
  }
  
}

@media screen and (min-width: 769px) {

  .btnHolder button {
    width: 330px;
  }
  
}
