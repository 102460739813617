
.container {
  composes: container from '../assets/css/index.css';
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-bottom: 56px;
}

.container h1 {
  line-height: 1.3;
}

@media screen and (min-width: 769px) {

  .container h1 {
    font-size: 26px;
  }

}