
.logo a {
  display: block;
}

.logo .clogo img {
  display: block;
  width: 26px;
}

.logo .ctlogo {
  display: none;
}

.logo .ctlogo img {
  display: block;
  width: 128px;
}

/* Media queries */

@media screen and (min-width: 769px) {
  
  .contact a {
    border-bottom: 0 none;
  }
  
  .logo .clogo {
    display: none;
  }
  
  .logo .ctlogo {
    display: block;
  }
  
}
