.field {
  display: flex;
  flex-direction: column;
}

.field label {
  color: #747474;
  flex: 1 1 100%;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 8px 38px 8px 16px;
  cursor: pointer;
  line-height: 1.2;
  min-height: 56px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.field label:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}

.field label:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.field label.selected {
  color: #3d3d3d;
}

.field label:focus-within {
  border-color: #23cd94;
  z-index: 1;
}

.field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  right: 16px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: solid 1px #747474;
  border-radius: 50%;
  transform: translateY(-54%);
}

.field label:hover input ~ .icon {
  background-color: #f2f7f5;
}

.field label input:checked ~ .icon {
  background-color: #38d19e;
  border-color: #38d19e;
}

.icon:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  background-color: #fff;
  display: none;
}

.field input:checked ~ .icon:after {
  display: block;
}

.hint {
  display: block;
  color: #747474;
  font-style: italic;
  font-size: 12px;
}

@media screen and (min-width: 769px) {

  .field label {
    padding: 12px 16px 12px 44px;
  }

  .field:not(.vertical) {
    flex-direction: row;
    min-height: 56px;
  }

  .field:not(.vertical) label {
    min-height: 0;
  }

  .field:not(.vertical) label:not(:last-child) {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    margin-right: -1px;
  }

  .field:not(.vertical) label:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .field:not(.vertical) label:last-child {
    border-top-right-radius: 4px;
  }

  .icon {
    right: auto;
    left: 16px;
  }

}