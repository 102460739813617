
.main {
  composes: main from '../assets/css/index.css';
  text-align: center;
}

.main h1 {
  margin-top: 80px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-bottom: 30px;
}

.safeData {
  font-size: 12px;
  color: #81909d;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.safeData i {
  font-size: 21px;
  opacity: 0.5;
  margin: -2px 8px 0 0;
}

.terms {
  font-size: 12px;
  padding-top: 16px;
  justify-content: center;
}

.terms span[class^="checkbox_icon__"] {
  top: 0;
}

/* Media queries */

@media screen and (min-width: 769px) {

  .safeData {
    margin-bottom: 50px;
  }
  
}
