
.field {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif, arial, Helvetica;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  text-decoration: none !important;
  padding: 6px 24px;
  min-height: 56px;
  background-color: #38d19e;
  border: solid 2px #38d19e;
  border-radius: 4px;
  width: 100%;
  outline: none;
  transition: all .2s linear;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.secondary {
  composes: field;
  background-color: #2b6eec;
  border: solid 2px #2b6eec;
}

.light {
  composes: field;
  color: #009865 !important;
  background-color: #fff;
}

.dark {
  composes: field;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.transparent {
  composes: field;
  background-color: transparent !important;
}

.shadow {
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
}

.field:hover:not([disabled]),
.field:active:not([disabled]) {
  text-decoration: none;
  background-color: #009865;
  border: solid 2px #009865;
  cursor: pointer;
}

.secondary:hover:not([disabled]),
.secondary:active:not([disabled]) {
  background-color: #0038a1;
  border: solid 2px #0038a1;
}

.light:hover:not([disabled]),
.light:active:not([disabled]) {
  color: #0b593f !important;
  background-color: #fff;
  border: solid 2px #0b593f;
}

.dark:hover:not([disabled]),
.dark:active:not([disabled]) {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.field:disabled, .field[disabled] {
  opacity: 0.32;
  text-decoration: none;
  cursor: default;
}

.icon {
  margin-right: 8px;
  font-size: 24px;
}


/* Media queries */

@media screen and (min-width: 769px) {

  .field {
    min-width: 284px;
    width: auto;
  }
  
}
