
.allow-overflow .slick-slider .slick-list {
  overflow: visible;
}

.slick-slider .slick-dots {
  text-align: center;
}

.slick-slider .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-slider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-slider .slick-dots li button:hover, 
.slick-slider .slick-dots li button:focus {
  outline: none;
}

.slick-slider .slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  content: "";
  background-color: #6da1ff;
  border-radius: 7px;
  opacity: 0.3;
  transition: all 300ms ease;
}

.slick-slider .slick-dots li.slick-active button:before {
  background-color: #0038a1;
  opacity: 1;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before, .slick-next:before {
  font-family: "cticon";
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #1e1e1e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:hover:before, .slick-next:hover:before {
  opacity: 1;
}

.slick-prev {
  left: -25px;
}

.slick-prev:before {
  content: "\f106";
}

.slick-next:before {
  content: "\f109";
}

.slick-next {
  right: -25px;
}

@media screen and (min-width: 561px) {

  .slick-slider {
    margin: 0;
  }
  
  .slick-slider .slick-dots {
    margin-top: -5px;
  }
  
}
