 
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.4s;
}

.modal::before {
  content: " ";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1e1e1e;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.3s, bottom 0.1s linear 0.3s;
}

.show {
  composes: modal;
  top: 0;
  opacity: 1;
  transform: translateY(0);
}

.show::before {
  bottom: -100%;
  opacity: 0.64;
  transition: opacity 0.3s;
}

.backdropCloseable::before {
  cursor: pointer;
}

.dialog {
  position: relative;
  top: 50%;
  margin: 0 20px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  height: auto;
  min-height: 100px;
  max-height: calc(100% - 40px);
  outline: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateY(-50%);
  z-index: 102;
}

.content {
  font-size: 15px;
  line-height: 1.33;
  position: relative;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.container {
  overflow-x: hidden;
  overflow-y: auto;
}

.container > div {
  padding: 0 24px;
  position: relative;
}

.content h2 {
  font-size: 18px;
  padding: 0;
  margin: 0 38px 12px 24px;
}

.content h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: #333;
  margin: 24px 0 8px;
}

.iconClose {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 24px;
  color: #3d3d3d;
  cursor: pointer;
  z-index: 103;
  transition: color .1s linear;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.iconClose:hover {
  color: #1e1e1e;
}


/* Media queries */

@media screen and (min-width: 769px) {

  .dialog {
    margin: 0 32px;
  }
  
  .content {
    font-size: 16px;
    line-height: 1.5;
    padding: 24px 0;
  }
  
  .container > div {
    padding: 0 32px;
  }

  .content h2 {
    font-size: 24px;
    margin: 0 54px 8px 32px;
  }
  
  .content h3 {
    font-size: 16px;
    margin: 24px 0 10px;
  }

  .iconClose {
    top: 12px;
    right: 18px;
    font-size: 32px;
  }
  
}

@media screen and (min-width: 993px) {
  
  .dialog {
    margin: 0 auto;
    width: 736px;
    height: auto;
    max-height: 85%;
  }
  
}

@media screen and (min-width: 1201px) {
  
  .dialog {
    width: 796px;
  }
  
}
