
.panel {
  padding: 16px;
  margin: 20px 0;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
}

.title {
  color: #3d3d3d;
  font-weight: 700;
  line-height: 1.5;
  display: block;
  padding: 15px 16px;
  margin: -16px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.icon {
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  transition: transform 0.2s linear;
}

.expandable .title {
  cursor: pointer;
}

.expanded .icon {
  transform: rotate(-180deg) translateY(3px);
}

.title ~ .content {
  margin-top: 12px;
}

.extension {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.15s linear;
}

.expanded .extension {
  margin-top: 15px;
  max-height: 1000px;
  opacity: 1;
}

@media screen and (min-width: 769px) {

  .panel {
    padding: 24px;
  }

  .title {
    padding: 19px 24px;
    margin: -24px;
  }

  .icon {
    top: 20px;
    right: 16px;
  }

  .title .icon {
    top: 16px;
  }

  .expanded .extension {
    margin-top: 20px;
  }

}