
.container {
  composes: container from '../assets/css/index.css';
}

.container h1 {
  margin-top: 32px;
}

.container h2 {
  margin-bottom: 28px;
}

.container h3 {
  font-size: 16px;
}

.container h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.33;
}

.card {
  margin-top: 64px;
}

.card img {
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
  width: 105px;
  margin: 0 auto;
}

.desc {
  font-size: 14px;
  line-height: 1.33;
  margin-top: 4px;
}

.insurance {
  margin: 16px 0;
}

.insurance > div {
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  padding: 16px;
  margin: 0;
}

.insurance > div:first-child {
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.insurance > div:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.insurance h4 {
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insurance h4.selected {
  color: #1e1e1e;
}

.insuranceItem {
  padding: 0;
}

.insuranceItem label {
  flex: 1 1 auto;
}

.serviceDesc {
  color: #747474;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.notice {
  font-size: 13px;
  line-height: 1.4;
  margin-top: -8px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 0;
}

.skip {
  margin-top: 20px;
}

@media screen and (min-width: 769px) {

  .insurance > div:first-child {
    border-width: 1px;
    border-radius: 4px;
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .insurance > div:last-child {
    border-width: 1px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
}