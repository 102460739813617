
.ccBackBtn, a.ccBackBtn, a.ccBackBtn:visited {
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.ccBackBtn:hover, a.ccBackBtn:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.ccBackBtn span {
  font-size: 13px;
  margin: -2px 0 0 3px;
}
