
.demo {
  text-align: left;
  padding: 0 24px;
}

.container {
  composes: container from '../assets/css/index.css';
  overflow: visible;
  line-height: 1.5;
}

.container a, .container a:visited {
  color: #009865;
  text-decoration: none;
}

.container a:hover, .container a:active, .container a:focus {
  color: #0b593f;
  text-decoration: underline;
}

.container h3 {
  color: #2b6eec;
  font-size: 18px;
  padding-bottom: 2px;
  margin: 40px 0 12px;
  border-bottom: solid 1px #2b6eec;
}

.container h3:first-child {
  margin-top: 70px;
}

.demoScreen {
  color: #a4a4a4;
  font-size: 14px;
}

@media screen and (min-width: 769px) {

  .container {
    width: 700px;
  }

}