
.slider {
  margin: 8px -24px 0;
}

.slider > div {
  margin: 0 -64px;
}

.sliderItem {
  border-radius: 8px;
  margin: 8px;
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.slidePanel {
  font-size: 18px;
  background-color: #fff;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  height: 678px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.slideTitle {
  padding: 24px 24px 0;
}

.slideTitle h3 {
  font-size: 32px;
  letter-spacing: 0.5px;
  margin: 0;
}

.slideTitle h4 {
  color: #72809c;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.slideTitle > div {
  color: #212f52;
  line-height: 1.2;
  min-height: 44px;
  margin-top: 6px;
}

.slideImg {
  height: 172px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.slideData {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.slideInfo {
  padding: 16px 16px 16px 22px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.slideInfo ul {
  list-style: none;
}

.slideInfo li {
  color: #3d3d3d;
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  padding-left: 16px;
  margin-bottom: 12px;
  position: relative;
}

.slideInfo li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #154dd1;
  border-radius: 3px;
}

.slideIcons {
  display: flex;
  flex-wrap: wrap;
  margin: 8px -2px 0;
}

.slideIcons > div {
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.iconEQ,
.iconEQo {
  background-color: #0d7719;
}
.iconBo {
  background-color: #5779a8;
}
.iconMF {
  background-color: #dfae27;
}
.iconETF,
.iconETP,
.iconETN,
.iconETC {
  background-color: #27a39c;
}
.iconFX,
.iconFXo,
.iconCFX {
  background-color: #9e2e23;
}
.iconCFD,
.iconCFDo {
  background-color: #661eda;
}
.iconFU {
  background-color: #3aaa99;
}
.iconCo {
  background-color: #fd6a23;
}

.slideSelect {
  padding: 0 16px 16px;
}

.slideSelect a {
  color: #3d3d3d;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: #fff;
  border: 2px solid #3d3d3d;
  border-radius: 4px;
  transition: all .1s linear;
}

.slideSelect a i {
  font-size: 0px;
  visibility: hidden;
  transition: font-size .1s linear;
}

.slideSelect a:hover {
  color: #fff;
  background-color: #3d3d3d;
}

.slideSelect a span {
  margin: 0 8px;
}

.slider .popular {
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.29;
  letter-spacing: 0.23px;
  text-align: center;
  padding: 0 24px;
  background-color: #2b6eec;
  border-radius: 4px;
  z-index: 1;
}

.sliderDots {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.sliderSelItem .slideSelect a {
  color: #fff;
  background-color: #3d3d3d;
}

.sliderSelItem .slideSelect a i {
  font-size: 28px;
  visibility: visible;
}

.confirm {
  font-size: 14px;
  margin-top: 32px;
}

.confirm span[class^="checkbox_icon__"] {
  top: 0;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 16px;
}

.leaveModalInfo {
  text-align: center;
}

.leaveModalInfo h2 {
  font-size: 18px;
}

.leaveModalBtnHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.leaveModalBtnHolder button {
  max-width: 360px;
  margin-top: 16px;
}

.leaveModalBtnHolder button {
  margin-top: 16px;
}

.leaveModalYesCancel {
  text-align: center;
  margin: 24px 0;
}

.leaveModalYesCancel a, .leaveModalYesCancel a:active, .leaveModalYesCancel a:visited {
  font-size: 14px;
  text-decoration: underline;
}

.leaveModalYesCancel a:hover {
  text-decoration: underline;
}

.modalView {
  overflow: hidden;
  margin: 0 -24px;
}

.modalView .content {
  width: 325px;
  margin: 0 auto;
}

.modalView h1 {
  font-size: 20px;
  text-align: center;
  padding: 0 16px;
  margin: 10px 0;
}

.modalView h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 0 16px;
  margin: 12px 0 10px;
}

.modalView .slidePanel {
  height: 560px;
}

.modalView .slideImg {
  height: 80px;
  margin-top: 12px;
  align-items: flex-start;
}

@media screen and (min-width: 561px) {

  .slider > div {
    margin: 0;
  }

  .slideIcons > div {
    font-size: 12px;
    height: 32px;
    width: 32px;
  }

  .modalView .content {
    width: 416px;
  }
  
}

@media screen and (min-width: 769px) {

  .slider {
    margin: 16px -24px 0;
  }

  .slideInfo {
    padding: 24px 24px 20px;
  }

  .slideSelect {
    padding-bottom: 24px;
  }

  .slideSelect a {
    max-width: 250px;
    margin: 0 auto;
  }

  .sliderDots {
    margin-top: 32px;
  }

  .confirm {
    margin-top: 22px;
  }

  .leaveModalInfo h2 {
    font-size: 24px;
  }

  .modalView {
    margin: 0 -40px;
  }

  .modalView .content {
    width: 566px;
    margin: 0 auto;
  }

  .modalView h1 {
    font-size: 24px;
    padding: 0;
  }

  .modalView h2 {
    font-size: 18px;
    padding: 0;
    margin-bottom: 32px;
  }

  .modalView .slidePanel {
    height: 582px;
  }

}

@media screen and (min-width: 1201px) {
  
  .slider {
    display: flex;
    justify-content: center;
  }

  .slider > div {
    width: 1153px;
  }

  .slidePanel {
    height: 700px;
  }

  .slider [class=slick-dots] {
    display: none;
  }

}