
.referral {
  color: #333;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  padding: 24px 24px 28px;
  border: solid 1px #2b6eec;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 80px 0 rgba(43, 110, 236, 0.08);
}

.referral h3 {
  color: #2b6eec;
  font-size: 22px;
  line-height: 1.2;
  margin: 0 0 8px;
}

.referral strong {
  white-space: nowrap;
}

.btnHolder {
  text-align: center;
  margin-top: 20px;
}

.btnHolder > a {
  font-size: 18px;
  padding: 6px 40px;
  min-width: auto;
}

.backHome {
  text-align: center; 
  margin: 40px 0 50px;
}

@media screen and (min-width: 769px) {

  .referral {
    padding: 32px;
  }

  .referral h3 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .btnHolder {
    margin-top: 24px;
  }

}