
.taxCountry {
  position: relative;
}

.taxCountryName {
  color: #3d3d3d;
  padding: 0 94px 0 16px;
  display: flex;
  align-items: center;
  height: 56px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
}

.taxCountryTools {
  position: absolute;
  top: 10px;
  right: 3px;
  z-index: 1;
}

.taxCountryTools i {
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 44px;
  cursor: pointer;
}

.taxCountryTools i:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}

.taxCountryTools i.delete,
.taxCountryTools i.edit {
  color: #747474;
}

.taxCountryTools i.delete:hover {
  color: #cc272a;
}

.taxCountryTools i.edit:hover {
  color: #009865;
}

.addTaxCountry {
  margin: 16px 0;
}

.addTaxCountry a {
  color: #23cd94;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.addTaxCountry a:hover {
  color: #009865;
}

.addTaxCountry a i {
  font-size: 18px;
  margin-left: 6px;
}

.taxModal h2 {
  margin-bottom: 24px;
}

.taxModalBtnHolder {
  text-align: center;
  margin-top: 12px;
}

.taxModalBtnHolder button, .taxModalBtnHolder a {
  font-size: 18px;
  min-height: 56px;
}

.taxModalPlaceholder {
  height: 76px;
}

.hidden {
  display: none;
}

@media screen and (min-width: 769px) {

  .fundsOrigin {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .fundsOrigin label {
    flex: 0 1 50%;
    min-height: 56px !important;
    margin-top: -1px;
  }

  .taxModal h2 {
    font-size: 24px;
    text-align: center;
  }

}