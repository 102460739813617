.field {
  display: flex;
  flex-direction: column;
}

.field label {
  color: #747474;
  flex: 1 1 100%;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 38px 0 16px;
  cursor: pointer;
  line-height: 1.2;
  min-height: 56px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.field label:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}

.field label:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.field label.selected {
  color: #3d3d3d;
}

.field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  right: 16px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: solid 1px #747474;
  border-radius: 2px;
  transform: translateY(-54%);
}

.field label:hover input ~ .icon {
  background-color: #f2f7f5;
}

.field label input:checked ~ .icon {
  background-color: #38d19e;
  border-color: #38d19e;
}

.icon:after {
  content: "";
  position: absolute;
  left: 5px;
  top: -1px;
  width: 4px;
  height: 11px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  display: none;
}

.field input:checked ~ .icon:after {
  display: block;
}

.circle {
  composes: field;
}

.circle .icon {
  border-radius: 13px;
}

@media screen and (min-width: 769px) {

  .field label {
    padding: 0 16px 0 44px;
  }

  .field:not(.vertical) {
    flex-direction: row;
    min-height: 56px;
  }

  .field:not(.vertical) label {
    min-height: 0;
  }

  .field:not(.vertical) label:not(:last-child) {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    margin-right: -1px;
  }

  .field:not(.vertical) label:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .field:not(.vertical) label:last-child {
    border-top-right-radius: 4px;
  }

  .icon {
    left: 16px;
    right: auto;
  }

}