
/* Generic rules */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, #root {
  border: 0;
  font-size: 16px;
  line-height: 23px;
  font-family: 'Noto Sans', sans-serif, arial, Helvetica;
  color: #1e1e1e;
  background-color: #fff;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, #root, #root > div {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e1e;
  font-family: 'Noto Sans', sans-serif, arial, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  margin: 0;
}

a, a:visited {
  color: #3d3d3d;
  text-decoration: underline;
}

a:hover, a:active, a:focus {
  color: #1e1e1e;
  text-decoration: underline;
}

input, textarea, select {
  font-family: 'Noto Sans', sans-serif, arial, Helvetica;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

h1 {
  font-size: 38px;
  margin: 32px 24px 16px;
}

h1.icon::before {
  content: "\f100";
  font-family: 'cticon';
  font-size: 24px;
  color: #fff;
  line-height: 42px;
  display: block;
  margin: 0 auto 10px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
  background-color: #38d19e;
}

h2 {
  font-size: 28px;
  padding: 0 24px;
  margin-bottom: 16px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

img {
  display: block;
  max-width: 100%;
}

.lowercase {
  text-transform: lowercase;
}

.main {
  flex: 1 0 auto;
  background-color: #fafafa;
  overflow: hidden;
}

section.darker {
  background-color: #f3f3f3;
}

.content {
  padding: 14px 24px;
}

.main h1 {
  font-size: 24px;
  text-align: center;
  margin-top: 32px;
}

.main h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  text-align: center;
}

.main h3 {
  font-size: 14px;
  line-height: 1.33;
  margin: 4px 0;
}

.main label {
  text-align: left;
}

.btnHolder {
  text-align: center;
  margin: 10px 0 70px;
}

.btnHolder button, .btnHolder a {
  font-size: 18px;
  min-height: 56px;
}


/* 
 * Media queries 
 */

@media screen and (min-width: 561px) {

  .container {
    width: 566px;
    margin: 0 auto;
  }

  .content {
    width: 440px;
    margin: 0 auto;
    padding: 14px 0;
  }

}

@media screen and (min-width: 769px) {

  h1 {
    margin: 64px 24px 16px;
  }

  h2 {
    padding: 0;
  }

  .main h1 {
    font-size: 32px;
    margin-top: 76px;
  }

  .content {
    width: 100%;
  }

  .btnHolder {
    margin-top: 20px;
  }

}


/* 
 * Animation 
 */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1.25s linear infinite;
}
