
.slider {
  margin: 0 -24px;
}

.slider > div {
  margin: 0 -64px;
}

.sliderItem {
  border-radius: 10px;
  margin: 8px;
  background-color: #dde8fc;
  background-image: linear-gradient(to right, #dde8fc 50%, #dde8fc 100%);
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
  transition: background-color 0.15s linear;
}

.sliderItemFLEX {
  composes: sliderItem;
}

.sliderItemINVEST {
  composes: sliderItem;
  background-image: linear-gradient(to right, #dde8fc 50%, #e2fdf4 100%);
}

.sliderItemADVANCE {
  composes: sliderItem;
  background-image: linear-gradient(to right, #dde8fc 50%, #ffe6e6 100%);
}

.sliderPanel {
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.sliderImg {
  border-radius: 8px;
  overflow: hidden;
}

.sliderImg img {
  width: 100%;
}

.sliderInfo {
  padding: 12px;
}

.sliderInfo h3 {
  font-size: 24px;
  margin: 12px 0 8px;
}

.sliderInfo a, .sliderInfo a:visited {
  font-size: 80%;
  display: inline-block;
  margin-top: 10px;
}

.sliderSelect {
  color: #00143b;
  font-weight: 700;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}

.sliderSelect span[class^="checkbox_icon__"] {
  border-color: #fff;
}

.sliderSelect label input ~ span[class^='checkbox_icon__']:after {
  border: solid #0038a1;
  border-width: 0 1px 1px 0;
}

.sliderSelect label input:checked ~ span[class^='checkbox_icon__'] {
  background-color: #fff;
  border-color: #fff;
}

.sliderDots {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.sliderSelItem {
  background-color: #0038a1;
  border: 2px solid #0038a1;
  background-image: linear-gradient(to right, #0038a1 50%, #2b6eec 100%);
  border-image: linear-gradient(to right, #0038A0 50%, #2b6eec 100%);
  border-image-slice: 1;
}

.sliderItemINVEST.sliderSelItem {
  background-image: linear-gradient(to right, #0038a1 50%, #4ef1bb 100%);
  border-image: linear-gradient(to right, #0038a1 50%, #4ef1bb 100%);
  border-image-slice: 1;
}

.sliderItemADVANCE.sliderSelItem {
  background-image: linear-gradient(to right, #0038a1 50%, #3d3d3d 100%);
  border-image: linear-gradient(to right, #0038a1 50%, #3d3d3d 100%);
  border-image-slice: 1;
}

.sliderSelItem .sliderPanel {
  padding: 6px 6px 8px;
}

.sliderSelItem .sliderSelect {
  color: #fff;
  padding-bottom: 18px;
}

.confirm {
  font-size: 12px;
  margin-top: 16px;
}

.confirm span[class^="checkbox_icon__"] {
  top: 0;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 40px;
}

.leaveModalInfo {
  text-align: center;
}

.leaveModalInfo h2 {
  font-size: 18px;
}

.leaveModalBtnHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.leaveModalBtnHolder button {
  max-width: 360px;
  margin-top: 16px;
}

.leaveModalBtnHolder button {
  margin-top: 16px;
}

.leaveModalYesCancel {
  text-align: center;
  margin: 24px 0;
}

.leaveModalYesCancel a, .leaveModalYesCancel a:active, .leaveModalYesCancel a:visited {
  font-size: 13px;
  text-decoration: underline;
}

.leaveModalYesCancel a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 561px) {
  
  .slider > div {
    margin: 0;
  }
  
}

@media screen and (min-width: 1200px) {

  .slider [class=slick-track] {
    transform: translate3d(-33.33%, 0, 0) !important;
  }

  .slider [class=slick-dots] {
    display: none;
  }

}

@media screen and (min-width: 769px) {

  .sliderItem {
    margin: 12px;
  }

  .sliderPanel {
    padding: 16px;
  }

  .sliderInfo {
    padding: 16px;
  }

  .sliderSelItem .sliderPanel {
    padding: 14px 14px 16px;
  }

  .sliderDots {
    margin-top: 32px;
  }

  .confirm {
    margin-top: 22px;
  }

  .leaveModalInfo h2 {
    font-size: 24px;
  }

}