
.container {
  composes: container from '../assets/css/index.css';
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-bottom: 56px;
}

.container h1 {
  line-height: 1.3;
}

.container h4 {
  color: #000;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.container h4 i {
  font-size: 18px;
  margin-right: 4px;
}

.container p {
  color: #747474;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.darker {
  composes: darker from '../assets/css/index.css';
  padding: 40px 0;
  margin: 32px 0 64px;
}

.darker h1 {
  margin-top: 0;
}

.forward {
  text-align: center;
  padding: 0 24px;
  margin-top: 40px;
}

.forward a {
  color: #23cd94;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.forward a:hover {
  color: #009865;
}

@media screen and (min-width: 769px) {

  .container h1 {
    font-size: 26px;
  }

}