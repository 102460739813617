
header {
  flex: 0 0 69px;
}

header.withPlatform {
  flex: 0 0 119px;
}

header > div {
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.container {
  padding-top: 20px;
  height: 69px;
  position: relative;
}

.contact {
  color: #3d3d3d;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  flex: 1;
}

.contactRight {
  composes: contact;
  position: absolute;
  top: 26px;
  right: 24px;
  margin: 0;
  padding: 0;
}

.contact span {
  margin: 0 3px;
}

.contact:not(.contactRight) span {
  display: block;
}

.contact a {
  color: #2b6eec;
  text-decoration: none;
  margin: 0 3px;
}

.logo {
  position: absolute;
  top: 22px;
  left: 24px;
}

.logoRight {
  composes: logo;
  left: auto;
  right: 24px;
}

.backBtn {
  position: absolute;
  top: 24px;
  left: 18px;
}

header.withProgress .container {
  padding-top: 14px;
  height: 77px;
}

header.withProgress .contact span {
  display: inline;
}

header.withProgress .backBtn {
  top: 39px;
}

header.withProgress .logo {
  top: 34px;
}

.platform {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0741ad;
  padding: 0 24px;
  height: 50px;
}

.platformName {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
}

.platform a, .platform a:visited, .platform a:hover, .platform a:active, .platform a:focus {
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2b6eec;
  padding: 0 16px;
  height: 30px;
  border-radius: 4px;
  min-width: 110px;
  transition: background-color .15s linear;
}

.platform a:hover, .platform a:active, .platform a:focus {
  background-color: #0038a1;
}

.progress {
  position: absolute;
  top: 48px;
  left: 84px;
  right: 84px;
  height: 3px;
  overflow: hidden;
  border-radius: 2px;
  background-color: #e4e4e4;
  z-index: 1;
}

.progress > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10%;
  background-color: #2b6eec;
}


/* Media queries */

@media screen and (min-width: 769px) {
  
  header.withPlatform {
    flex: 0 0 125px;
  }

  .contact {
    font-size: 13px;
    margin: 0 164px;
  }

  .contactRight {
    right: 32px;
    margin: 0;
  }

  .logo {
    top: 28px;
    left: 32px;
  }

  .logoRight {
    left: auto;
    right: 32px;
  }

  .backBtn {
    left: 26px;
  }

  .platform {
    justify-content: center;
    height: 56px;
  }

  .platform a {
    margin-left: 16px;
  }

  .progress {
    height: 4px;
    left: 50%;
    right: auto;
    width: 50%;
    max-width: 480px;
    transform: translateX(-50%);
  }

  header.withProgress .logo {
    top: 43px;
  }
  
}

@media screen and (min-width: 1201px) {
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
}
