
.convertor {
  text-align: center;
  margin: 24px 0 32px;
}

.convertor a {
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

.convertor a {
  font-weight: 700;
}

.convertor i {
  font-size: 24px;
  margin-right: 8px;
}

