
.container {
  composes: container from '../assets/css/index.css';
}

.container h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.container h5 {
  color: #3d3d3d;
  font-size: 15px;
}

.card {
  margin-top: 32px;
}

.card img {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(30, 30, 30, 0.16);
  width: 324px;
  margin: 0 auto 48px;
}

.hlights {
  margin-bottom: 32px;
}

.hlights ul {
  margin-left: 10px;
}

.skip {
  text-align: center;
  position: relative;
  margin-bottom: 24px;
}

.skip:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 1px solid #ccc;
  z-index: 0;
}

.skip a, .skip a:visited {
  color: #888;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  line-height: 25px;
  background-color: #f8f8f8;
  padding: 0 10px;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.skip a:hover {
  text-decoration: none;
}

.serviceDesc {
  font-size: 14px;
  display: block;
  margin-top: 4px;
}

.notice {
  font-size: 13px;
  line-height: 1.4;
  margin-top: -8px;
}

.convertor {
  text-align: center;
  margin: 24px 0 32px;
}

.convertor a {
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

.convertor a {
  font-weight: 700;
}

.convertor i {
  font-size: 24px;
  margin-right: 8px;
}

.btnHolder {
  composes: btnHolder from '../assets/css/index.css';
  margin-top: 40px;
}

.btnHolder > div {
  margin-top: 40px;
}

@media screen and (min-width: 769px) {

  .card img {
    width: 400px;
  }

  .hlights ul {
    margin-left: 44px;
    padding-right: 24px;
  }

}